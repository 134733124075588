import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
    static targets = ["select"]
    connect() {
        console.log(this.selectTarget.getAttribute("data-max-items"))
        var maxItems = this.selectTarget.getAttribute("data-max-items")

        new TomSelect(this.selectTarget,{
            plugins: ['remove_button'],
            maxItems: maxItems,
            // onDelete: function(values) {
            //     return confirm(values.length > 1 ? "Are you sure you want to remove these " + values.length + " items?" : "Are you sure you want to remove " + values[0] + "?");
            // }
            // create: true,
            // sortField: {
            //     field: "text",
            //     direction: "asc"
            // }
        });
    }
}