// Impact Goal Controller for Districts and Schools
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["component", "field", "total", "grade", "year", "subject", "type"]
  static values = {
    cutScores: Array,
    ownerId: Number,
    ownerType: String
  }

  initialize() {
    this.sumtotal()
    if (this.ownerTypeValue == "Teacher") {
      this.getCutScores()
    }
  }

  sumtotal() {
    var total = 0
    this.componentTargets.forEach(component => {
      if (component.value) {
        total = total + (parseFloat(component.value) * parseFloat(component.dataset.multiplier))
      }
    })
    this.totalTarget.innerText = total

    if (total == 0) {
      this.gradeTarget.innerText = 'N/A'
    } else {
      const cutScores = this.cutScoresValue
      if (total >= cutScores[0]) {
        this.setGrade('A')
      } else if (total >= cutScores[1]) {
        this.setGrade('B')
      } else if (total >= cutScores[2]) {
        this.setGrade('C')
      } else if (total >= cutScores[3]) {
        this.setGrade('D')
      } else {
        this.setGrade('F')
      }
    }
  }

  getCutScores() {
    const impactTypeSpecific = this.typeTarget.value
    const subjects = this.getSelectValues(this.subjectTarget).join()
    const url = `/impact_goals/cut_scores?owner_id=${this.ownerIdValue}&owner_type=${this.ownerTypeValue}&year=${this.yearTarget.value}&impact_type_specific=${impactTypeSpecific}&subjects=${subjects}`
    fetch(url)
      .then(response => response.json())
      .then(data => {
        this.cutScoresValue = data.cut_scores
        this.fieldTargets.forEach(target => {
          if (data.fields.includes(target.dataset.fieldname)) {
            this.display(target)
          } else {
            this.hide(target)
          }
        })
      })
  }

  getSelectValues(select) {
    var result = [];
    var options = select && select.options;
    var opt;

    for (var i=0, iLen=options.length; i<iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result.push(opt.value || opt.text);
      }
    }
    return result;
  }

  setGrade(grade) {
    this.gradeTarget.innerText = grade
  }

  display(target) {
    target.classList.remove("hidden")
  }

  hide(target) {
    target.classList.add("hidden")
  }
}
